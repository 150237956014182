import React from "react"
import { graphql } from "gatsby"
import { Page } from "@web-punks/core"
import { withSanityPreview } from "@web-punks/contents-sanity"

interface Props {
  data: any
}

const TypPage = ({ data }: Props) => {
  return <Page id={data.sanitySlide?._id ?? undefined}>type</Page>
}

export default withSanityPreview(TypPage)

export const pageQuery = graphql`
  query TypPageQuery($id: String!) {
    sanityTyp(_id: { eq: $id }) {
      _id
      data {
        contents {
          _rawText
          game {
            _id
            data {
              layout {
                background {
                  asset {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
